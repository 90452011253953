import React from 'react'
import './mobileSlider.css'

const MobileSlider = (props) => {
  let className = 'mobile-slider'
  if (props.isMargin) {
    className += ' slider-margin'
  }

  return (
    <div className={className}>
      <img src={props.image} alt={props.title} style={{ width: '283px', borderRadius: '6px' }} />
      {props.type && (
        <p className='mobile-slider__subtitle' style={{ marginBottom: 8 }}>
          {props.type}
        </p>
      )}
      <h5 className='mobile-slider__title'>{props.title}</h5>
      {props.children}
      {props.date && (
        <p className='mobile-slider__subtitle' style={{ marginTop: 8 }}>
          {props.date}
        </p>
      )}
    </div>
  )
}

export default MobileSlider
