import React from 'react'
import './event.css'
import { CustomNavigation, MobileSlider } from '../../components'
import { Link } from 'react-router-dom'
import em_1 from '../../assets/event-1-m.png'
import em_2 from '../../assets/event-2-m.png'
import em_3 from '../../assets/event-3-m.png'
// import prev from '../../assets/prev.svg'
// import next from '../../assets/next.svg'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

const Events = () => {
  const swiper = useSwiper()
  return (
    <div className='events section__padding' id='events'>
      <CustomNavigation title='Мероприятия' id_next='nextE' id_prev='prevE' isDisabled />
      {/* <button className='prev' onClick={() => swiper.slidePrev()}>
        <img src={prev} alt='' />
      </button>
      <button className='next' onClick={() => swiper.slideNext()}>
        <img src={next} alt='' />
      </button> */}
      <div className='news_m'>
        <Link to='/events'>
          <MobileSlider type='Выставка' date='21 ноя, 2022 — 05 дек, 2022' title='Призыв взглянуть на&nbsp;стрит-арт с&nbsp;другой стороны' image='./images/Event1.png'></MobileSlider>
        </Link>
        <Link to='/eventssub'>
          <MobileSlider type='Выставка' date='12 — 16 июл, 2023' title='Парма арт-маркет' image='./images/parma-perm.png'></MobileSlider>
        </Link>
        {/* <MobileSlider type='Мит' date='09 сент, 2022' title='Лекции опытных художников, которые поделятся своими знаниями, опытом и&nbsp;техниками' image={em_2} isMargin></MobileSlider>
        <MobileSlider type='Мастер-класс' date='19 сент, 2022' title='Мастер-класс по&nbsp;эскизам. Окунемся в&nbsp;историю появления граффити' image={em_3}></MobileSlider> */}
      </div>
      <Swiper modules={[Navigation]} spaceBetween={20} slidesPerView={3} navigation={{ nextEl: '#nextE', prevEl: '#prevE' }} style={{ marginTop: 34 }} className='swiper_events'>
        {/* <button className='prevE' onClick={(swiper) => swiper.slidePrev()} id='prevE'>
          <img src={prev} alt='' />
        </button>
        <button className='nextE' onClick={(swiper) => swiper.slideNext()} id='nextE'>
          <img src={next} alt='' />
        </button> */}
        {/* <div className='events__list'> */}
        <SwiperSlide>
          <Link to='/events'>
            <div className='events__list-item'>
              <img src='./images/Event1.png' alt='' />
              <p className='events__list-item__type'>Выставка</p>
              <h4 className='events__list-item__title'>
                Призыв взглянуть
                <br />
                на&nbsp;стрит-арт с&nbsp;другой стороны
              </h4>
              <p className='events__list-item__date'>21 ноя, 2022 — 05 дек, 2022</p>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to='/eventssub'>
            <div className='events__list-item'>
              <img src='./images/parma-perm.png' alt='' />
              <p className='events__list-item__type'>Выставка</p>
              <h4 className='events__list-item__title'>Парма арт-маркет</h4>
              <p className='events__list-item__date'>12 — 16 июл, 2023</p>
            </div>
          </Link>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className='events__list-item'>
            <img src={event_2} alt='' />
            <p className='events__list-item__type'>Мит</p>
            <h4 className='events__list-item__title'>Лекции опытных художников, которые поделятся своими знаниями, опытом и техниками</h4>
            <p className='events__list-item__date'>09 сент, 2022</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='events__list-item'>
            <img src={event_3} alt='' />
            <p className='events__list-item__type'>Мастер-класс</p>
            <h4 className='events__list-item__title'>Мастер-класс по эскизам. Окунемся в историю появления граффити</h4>
            <p className='events__list-item__date'>19 сент, 2022</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='events__list-item'>
            <img src={event_2} alt='' />
            <p className='events__list-item__type'>Мит</p>
            <h4 className='events__list-item__title'>Лекции опытных художников, которые поделятся своими знаниями, опытом и техниками</h4>
            <p className='events__list-item__date'>09 сент, 2022</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='events__list-item'>
            <img src={event_1} alt='' />
            <p className='events__list-item__type'>Мит</p>
            <h4 className='events__list-item__title'>Первое мероприятие на&nbsp;знакомство. Мы расскажем о&nbsp;масштабах проекта</h4>
            <p className='events__list-item__date'>29 авг, 2022</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='events__list-item'>
            <img src={event_3} alt='' />
            <p className='events__list-item__type'>Мастер-класс</p>
            <h4 className='events__list-item__title'>Мастер-класс по эскизам. Окунемся в историю появления граффити</h4>
            <p className='events__list-item__date'>19 сент, 2022</p>
          </div>
        </SwiperSlide> */}
        {/* </div> */}
      </Swiper>
    </div>
  )
}

export default Events
