import React from 'react'
import './news.css'
import { CustomNavigation, MobileSlider, Button } from '../../components'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

const News = () => {
  return (
    <div className='news section__padding' id='news'>
      <CustomNavigation title='Новости' id_next='nextN' id_prev='prevN' isDisabled />
      <div className='news_m'>
        <a href='https://59.ru/text/gorod/2023/04/28/72260057/' target='_blank' rel='noopener noreferrer'>
          <MobileSlider title='Студенты предложили идеи по преобразованию Перми' image='./images/news1m.png' style={{ width: '283px' }}>
            <p className='news__list-item__description'>В&nbsp;Перми прошел межвузовский форум &laquo;Меняем Пермь вместе&raquo;, на&nbsp;котором...</p>
          </MobileSlider>
        </a>
        <a href='https://www.sobaka.ru/prm/city/society/165145' target='_blank' rel='noopener noreferrer'>
          <MobileSlider title='В Перми появилась онлайн-платформа для уличных художников' image='./images/news2m.png' style={{ width: '283px' }}>
            <p className='news__list-item__description'>В Перми создали онлайн-платформу</p>
          </MobileSlider>
        </a>
        <a href='https://www.chitaitext.ru/novosti/dlya-ulichnykh-khudozhnikov-permi-poyavilas-karta-zdaniy-dlya-graffiti-/' target='_blank' rel='noopener noreferrer'>
          <MobileSlider title='Для уличных художников Перми появилась карта зданий для граффити' image='./images/news3m.png' style={{ width: '283px' }}>
            <p className='news__list-item__description'>В Перми появилась онлайн-платформа...</p>
          </MobileSlider>
        </a>
        {/* <MobileSlider title='История граффити' image={image_1}>
          <p className='news__list-item__description'>В Перми реализуется масштабный проект по созданию граффити, где каждый сможет принять участие!</p>
        </MobileSlider>
        <MobileSlider title='Коллаборация с&nbsp;художниками' image={image_2}>
          <p className='news__list-item__description'>Стиль один из самых важных аспектов для молодежи, поэтому мы решили сделать коллаборацию...</p>
        </MobileSlider>
        <MobileSlider title='Мероприятие по&nbsp;граффити' image={image_3}>
          <p className='news__list-item__description'>При изучении какой-либо темы очень важно окунуться в&nbsp;историю появления и&nbsp;сделать упор на теоретическую...</p>
        </MobileSlider> */}
      </div>
      <Swiper modules={[Navigation]} spaceBetween={20} slidesPerView={1} navigation={{ nextEl: '#nextN', prevEl: '#prevN' }} style={{ marginTop: 20 }}>
        <SwiperSlide style={{ width: '81.94vw' }}>
          <div className='news__list'>
            <div className='news__list-item item-1' style={{ backgroundImage: 'url(./images/news1.png', backdropFilter: 'blur(20px)' }}>
              <div className='news-full'>
                <div className='news__list-item__content'>
                  <p className='news__list-item__date'>28 апр, 2023</p>
                  <h4 className='news__list-item__title'>Студенты предложили идеи по&nbsp;преобразованию Перми</h4>
                  <p className='news__list-item__description'>В&nbsp;Перми прошел межвузовский форум &laquo;Меняем Пермь вместе&raquo;, на&nbsp;котором состоялся конкурс идей по&nbsp;развитию города. Участие в&nbsp;этом&nbsp;&mdash; это возможность участия студентов Перми в&nbsp;формировании комфортной городской среды, говорят организаторы.</p>
                  <p className='news__list-item__description'>Специальным призом Пермской городской думы отметили проект студентов Пермского филиала Президентской академии: &laquo;Интернет-платформа Граффити Пермь&raquo;.</p>
                  <a href='https://59.ru/text/gorod/2023/04/28/72260057/' target='_blank' rel='noopener noreferrer'>
                    <Button title='подробнее' isWhite />
                  </a>
                </div>
              </div>
              <div className='news__list-item__content'>
                <p className='news__list-item__date'>28 апр, 2023</p>
                <h4 className='news__list-item__title'>Студенты предложили идеи по&nbsp;преобразованию Перми</h4>
                <p className='news__list-item__description'>В&nbsp;Перми прошел межвузовский форум &laquo;Меняем Пермь вместе&raquo;, на&nbsp;котором состоялся конкурс идей по&nbsp;развити...</p>
                <a href='https://59.ru/text/gorod/2023/04/28/72260057/' target='_blank' rel='noopener noreferrer'>
                  <Button title='подробнее' isWhite />
                </a>
              </div>
            </div>
            <div className='lazy-container'>
              <div className='news__list-item item-2' style={{ backgroundImage: 'url(./images/news2.png' }}>
                <div className='news-full'>
                  <div className='news__list-item__content'>
                    <p className='news__list-item__date'>05 апр, 2023</p>
                    <h4 className='news__list-item__title'>В&nbsp;Перми появилась онлайн-платформа для уличных художников</h4>
                    <p className='news__list-item__description'>В&nbsp;Перми создали онлайн-платформу &laquo;Граффити Пермь&raquo; по&nbsp;поддержке и&nbsp;продвижению стрит-арта и&nbsp;художников...</p>
                    <a href='https://www.sobaka.ru/prm/city/society/165145' target='_blank' rel='noopener noreferrer'>
                      <Button title='подробнее' isWhite />
                    </a>
                  </div>
                </div>
                <div className='news__list-item__content'>
                  <p className='news__list-item__date'>05 апр, 2023</p>
                  <h4 className='news__list-item__title'>В&nbsp;Перми появилась онлайн-платформа для уличных художников</h4> <p className='news__list-item__description'>В&nbsp;Перми создали онлайн-платформу &laquo;Граффити Пермь&raquo; по&nbsp;поддержке и&nbsp;продвижению стрит-арта и&nbsp;художников...</p>
                  <a href='https://www.sobaka.ru/prm/city/society/165145' target='_blank' rel='noopener noreferrer'>
                    <Button title='подробнее' isWhite />
                  </a>
                </div>
              </div>

              <div className='news__list-item item-3' style={{ backgroundImage: 'url(./images/news3.png' }}>
                <div className='news-full'>
                  <div className='news__list-item__content'>
                    <p className='news__list-item__date'>04 апр, 2023</p>
                    <h4 className='news__list-item__title'>Для уличных художников Перми появилась карта зданий для граффити</h4>
                    <p className='news__list-item__description'>В&nbsp;Перми появилась онлайн-платформа &laquo;Граффити Пермь&raquo;, которая представляет несколько сервисов для&nbsp;у</p>
                    <a href='https://www.chitaitext.ru/novosti/dlya-ulichnykh-khudozhnikov-permi-poyavilas-karta-zdaniy-dlya-graffiti-/' target='_blank' rel='noopener noreferrer'>
                      <Button title='подробнее' isWhite />
                    </a>
                  </div>
                </div>
                <div className='news__list-item__content'>
                  <p className='news__list-item__date'>04 апр, 2023</p>
                  <h4 className='news__list-item__title'>Для уличных художников Перми появилась карта зданий для граффити</h4>
                  <p className='news__list-item__description'>В&nbsp;Перми появилась онлайн-платформа &laquo;Граффити Пермь&raquo;, которая представляет несколько сервисов для&nbsp;у</p>
                  <a href='https://www.chitaitext.ru/novosti/dlya-ulichnykh-khudozhnikov-permi-poyavilas-karta-zdaniy-dlya-graffiti-/' target='_blank' rel='noopener noreferrer'>
                    <Button title='подробнее' isWhite />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide style={{ width: '81.94vw' }}>
          <div className='news__list'>
            <div className='news__list-item item-1' style={{ backgroundImage: `url(${news_1})` }}>
              <div className='news__list-item__content'>
                <h4 className='news__list-item__title'>Мероприятие по граффити</h4>
                <p className='news__list-item__description'>В Перми реализуется масштабный проект по созданию граффити, где каждый сможет принять участие!</p>
                <Button title='Подробнее' isWhite />
              </div>
            </div>
            <div className='lazy-container'>
              <div className='news__list-item item-2' style={{ backgroundImage: `url(${news_2})` }}>
                <div className='news__list-item__content'>
                  <h4 className='news__list-item__title'>Коллаборация с художниками</h4>
                  <p className='news__list-item__description'>Стиль один из самых важных аспектов для молодежи, поэтому мы решили сделать коллаборацию...</p>
                  <Button title='Подробнее' isWhite />
                </div>
              </div>
              <div className='news__list-item item-3' style={{ backgroundImage: `url(${news_3})` }}>
                <div className='news__list-item__content'>
                  <h4 className='news__list-item__title'>История граффити</h4>
                  <p className='news__list-item__description'>При изучении какой-либо темы очень важно окунуться в&nbsp;историю появления и сделать упор на теоретическую...</p>
                  <Button title='Подробнее' isWhite />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
    </div>
  )
}

export default News
