import React, { useEffect } from 'react'
import { Header, Footer } from '../../containers'
import { useLocation } from 'react-router-dom'
import { Gallery } from '../../components'
import image from '../../data/image.json'

// import './eventsPage.css'

const schedule = [
  {
    date: '12 июл, 2023',
    title: 'Начало ярмарки',
  },
  { date: '13 июл, 2023', title: 'Дизайнерская одежда\nи украшения' },
  { date: '14 июл, 2023', title: 'Пермская арт-сцена\n(художники)' },
  { date: '15 июл, 2023', title: 'Детское творчество' },
  { date: '16 июл, 2023', title: 'Народно-художественные промыслы' },
]
const EventsPage = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location])
  return (
    <div className='App'>
      <Header />
      <div className='event-container hack-event '>
        <div className='event__status-container hack-event-m'>
          <p className='event__status event-text_secondary'>Проведённое мероприятие</p>
          <img src='./images/check.svg' alt='check mark' />
        </div>
        <div className='event__content'>
          <div className='event__info hack-event-m'>
            <div className='event__info-title__container'>
              <p className='event__info-type event-text_secondary'>Выставка</p>
              <h3 className='event__info-title event-text_primary'>Парма арт-маркет</h3>
              <p className='event__info-date event-text_secondary'>12 — 16 июл, 2023</p>
            </div>
            <div className='event__info-about'>
              <h3 className='event__info-title event-text_primary'>О мероприятии</h3>
              <p className='event__info-descr'>
                В&nbsp;Перми возле Соборной площади на&nbsp;Комсомольском проспекте, 4&nbsp;открылся фестиваль-ярмарка арт-крафт мастеров &quot;Парма Арт Маркет&laquo;Торговая площадка будет работать с&nbsp;15:00 до&nbsp;22:00&nbsp;с 12&nbsp;до&nbsp;16&nbsp;июля. Гостей &laquo;Парма Арт Маркета&raquo; ждёт насыщенная программа: выступления музыкантов в&nbsp;различных жанрах, розыгрыши подарков от&nbsp;мастеров, создание граффити, мастер-классы по&nbsp;росписи игрушки, раскрашиванию деревянной
                многослойной раскраски, созданию стильного образа, ковке и&nbsp;уличному искусству.
              </p>
            </div>
            <div className='event__place'>
              <h4 className='event__place-title'>Место проведения</h4>
              <p className='event__place-address'>Соборная площадь</p>
            </div>
          </div>
          <div className='event__gallery-container hack-event-gallery event-gal-m'>
            <Gallery images={image} />
          </div>
          <div className='event__schedule-container hack-event-m'>
            {schedule.map((event, i) => (
              <div className='event__schedule-item' key={i}>
                <p className='event__schedule-item__date  event-text_secondary'>{event.date}</p>
                <p className='event__schedule-item__title event-text_primary'>{event.title}</p>
              </div>
            ))}
          </div>
          <div className='event__gallery-container hack-event-gallery event-gal-d'>
            <Gallery images={image} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EventsPage
