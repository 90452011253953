import React from 'react'
import { InteractiveMap } from '../../containers'
import './touchMapPage.css'

const TouchMapPage = () => {
  return (
    <div className='App'>
      <InteractiveMap isPad isTouch />
    </div>
  )
}

export default TouchMapPage
