import { useState, React } from 'react'
import './profile.css'
import { Navigation } from 'swiper'
import 'swiper/css'
import { Avatar, Gallery } from '../../components'
import images from '../../data/images.json'
import 'swiper/css/navigation'
const Profile = ({ children, bio, name, role, avatarUrl, selectedCreator }) => {
  const [open, setOpen] = useState(false)
  const handleClick = (e) => {
    // console.log(e.target)
    // setOpen(!open)
  }
  console.log(selectedCreator)
  return (
    <div className='profile__wrap' onClick={handleClick}>
      <Avatar name={name} role={role} avatarUrl={avatarUrl} />
      {open && (
        <div className='profile__container'>
          <div className='avatar__container'>
            <Avatar name={name} role={role} avatarUrl={avatarUrl} />
          </div>
          <div className='bio__container'>
            <h3 className='bio__title'>О художнике</h3>
            <p className='bio'>{bio}</p>
          </div>
          <Gallery images={images} />
        </div>
      )}
    </div>
  )
}

export default Profile
