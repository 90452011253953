import React, { useState, useEffect } from 'react'
import './interactiveMap.css'
// import red_mark from '../../assets/red_mark.svg'
import { CustomNavigation, Button, MapInfo } from '../../components'
import locations from '../../data/locations.json'
import { Link } from 'react-router-dom'
import { YMaps, Map, Placemark, ZoomControl, FullscreenControl } from '@pbe/react-yandex-maps'

// const locations = [
//   {
//     id: 1,
//     coords: [58.007965, 56.219863],
//     title: 'Медведь',
//     status: 'Реализованный проект',
//     address: 'ул. Сысольская, 10',
//     info: 'Организаторы фестиваля поясняют, что медведь — символ Пермского края, по праву считается покровителем наших земель, олицетворяя собой черты регионального характера.\n В легендах разных народов этот таёжный зверь играет роль мудрого покровителя, именно поэтому считалось, что оберег с образом косолапого передает хозяину присущую этому зверю силу, спокойствие и чутьё. Также наши предки верили, что образ медведя охраняет жилище от негативных ситуаций.',
//     team: [{ name: 'Алексей Сендецкий', role: 'Individual style', imgUrl: './images/AC.png' }],
//     images: [
//       { id: 3, img: './images/bear1.png' },
//       { id: 4, img: './images/bear2.png' },
//     ],
//   },
//   {
//     id: 2,
//     coords: [58.013053, 56.249979],
//     status: 'Свободная локация',
//     address: 'ул. Пермская, 50',
//     images: [{ id: 1, img: './images/p50.png' }],
//   },
//   {
//     id: 3,
//     coords: [58.012729, 56.181788],
//     status: 'Свободная локация',
//     address: 'ул. Сергея Данщина, 1',
//     images: [{ id: 1, img: './images/s1.png' }],
//   },
//   {
//     id: 4,
//     coords: [58.07455, 55.733726],
//     status: 'Свободная локация',
//     address: 'ул. 50 лет Октября, 5а',
//     images: [{ id: 1, img: './images/o50.png' }],
//   },
//   {
//     id: 5,
//     coords: [58.003407, 56.232462],
//     title: 'Борьба с коронавирусной инфекцией',
//     status: 'Реализованный проект',
//     address: 'ул. Попова, 58',
//     info: 'Здание для создания граффити выбрано не случайно. Студенты-медики и выпускники медуниверситета были одними из первых, кто пришел на помощь врачам и жителям Прикамья в период самоизоляции. Молодые медики и сегодня продолжают свою трудовую и добровольческую деятельность.\nПо словам граффити-райтера, его работа рассказывает о вкладе каждого человека в борьбу с коронавирусной инфекцией, в котором особенно велика заслуга врачей, ученых и всех, кто оберегает и спасает людей.',
//     team: [{ name: 'Станислав Кислов', role: 'Individual style', imgUrl: './images/CK.png' }],
//     images: [
//       { id: 1, img: './images/virus1.png' },
//       { id: 2, img: './images/virus2.png' },
//     ],
//   },
// ]

const InteractiveMap = (props) => {
  const API_TOKEN = '182f7126-c1dd-40bd-8462-24676da5d1d9'

  const defaultState = { center: [57.990239, 56.252802], zoom: 12, controls: [] }

  const [points, setPoints] = useState(locations)

  const [filter, setFilter] = useState('all')

  const [selectedMark, setSelectedMark] = useState(null)

  const [isActive, setIsActive] = useState(1)

  const [isSwiped, setIsSwiped] = useState(false)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const minSwipeDistance = 50

  const handleFilterChange = (filter) => {
    setFilter(filter)
  }

  const filteredPoints = filter === 'all' ? points : points.filter((point) => point.status === filter)

  const handleMarkClick = (id) => {
    const info = filteredPoints.find((info) => info.id === id)
    selectedMark === info ? setSelectedMark(null) : setSelectedMark(info)
  }

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientY)
    console.log(e.targetTouches[0])
  }

  const onTouchMove = (e) => {
    e.preventDefault()
    setTouchEnd(e.targetTouches[0].clientY)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isUpSwipe = distance > minSwipeDistance
    const isDownSwipe = distance < -minSwipeDistance
    if (isUpSwipe || isDownSwipe) {
      isUpSwipe ? setIsSwiped(true) : setIsSwiped(false)
    }
  }

  const openTab = (e) => setIsActive(+e.target.dataset.index)

  let className = 'map-container'

  if (props.isNone) {
    className += ' none_class'
  }
  if (props.isPad) {
    className += ' section__padding'
  }

  return (
    <>
      <div className={className} id='map'>
        <CustomNavigation title='Карта проектов' isDisabled isMapPage />
        <div className='filterBar'>
          <div className='filterBar__tabs'>
            <button
              className={`${1 === isActive ? 'active' : 'filterBar__tabs-item '}`}
              onClick={(e) => {
                openTab(e)
                handleFilterChange('all')
              }}
              data-index={1}
            >
              Все
            </button>
            <button
              className={`${2 === isActive ? 'active' : 'filterBar__tabs-item '}`}
              onClick={(e) => {
                openTab(e)
                handleFilterChange('Свободная локация')
              }}
              data-index={2}
            >
              Свободная&nbsp;локация
            </button>
            <button
              className={`${3 === isActive ? 'active' : 'filterBar__tabs-item '}`}
              onClick={(e) => {
                openTab(e)
                handleFilterChange('Реализованный проект')
              }}
              data-index={3}
            >
              Реализованный&nbsp;проект
            </button>
          </div>
          {/* <input placeholder='Поиск' type='search' id='search' className='searchBar' />
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className='search-icon'>
            <path
              d='M15.7832 14.3911L20 18.6069L18.6069 20L14.3911 15.7832C12.8224 17.0407 10.8713 17.7246 8.86088 17.7218C3.96968 17.7218 0 13.7521 0 8.86088C0 3.96968 3.96968 0 8.86088 0C13.7521 0 17.7218 3.96968 17.7218 8.86088C17.7246 10.8713 17.0407 12.8224 15.7832 14.3911ZM13.8082 13.6605C15.0577 12.3756 15.7555 10.6532 15.7527 8.86088C15.7527 5.05267 12.6681 1.96909 8.86088 1.96909C5.05267 1.96909 1.96909 5.05267 1.96909 8.86088C1.96909 12.6681 5.05267 15.7527 8.86088 15.7527C10.6532 15.7555 12.3756 15.0577 13.6605 13.8082L13.8082 13.6605Z'
              fill='#111111'
            />
          </svg> */}
        </div>

        <div className='info-container'>
          <YMaps query={{ apikey: API_TOKEN }}>
            <Map defaultState={defaultState} className='yandexMap'>
              {/* <SearchControl
                options={{
                  fitMaxWidth: true,
                  float: 'right',
                }}
                style={{ zIndex: 999 }}
              /> */}
              <ZoomControl
                options={{
                  position: { top: 108, right: 10, bottom: 'auto', left: 'auto' },
                }}
              />
              <FullscreenControl />
              {filteredPoints.map((item) => (
                <Placemark geometry={item.coords} options={{}} onClick={() => handleMarkClick(item.id)} key={item.id} />
              ))}

              {selectedMark && <MapInfo title={selectedMark.title} status={selectedMark.status} address={selectedMark.address} images={selectedMark.images} info={selectedMark.info} team={selectedMark.team} />}
              {/* {selectedMark && (
                <div className={`${isSwiped ? 'info info_up' : 'info info_down'}`} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                  <div className='info__name'>
                    <p className='info__name-type'>Реализованный проект</p>
                    <h4 className='info__name-title'>Пермь Великая</h4>
                    <p className='info__name-address'>ул. Чкалова, 33б</p>
                  </div>
                  <div className='info__gallery'>
                    <div className='info__gallery-main'>
                      <img src={img_1} alt='' />
                    </div>
                    <div className='info__gallery-list'>
                      <div className='info__gallery-list__item'>
                        <img src={img_6} alt='' />
                      </div>
                      <div className='info__gallery-list__item'>
                        <img src={img_2} alt='' />
                      </div>
                      <div className='info__gallery-list__item'>
                        <img src={img_3} alt='' />
                      </div>
                      <div className='info__gallery-list__item'>
                        <img src={img_4} alt='' />
                      </div>
                      <div className='info__gallery-list__item'>
                        <img src={img_5} alt='' />
                      </div>
                      <div className='info__gallery-list__item'>
                        <img src={img_3} alt='' />
                      </div>
                    </div>
                  </div>
                  <div className='info__about'>
                    <h5 className='info__about-title'>О проекте</h5>
                    <p className='info__about-descr'>Проект был создан для того, чтобы подготовить новый квартал под арт-пространство будушего “Культурного дома Перми”.</p>
                  </div>
                  <div className='info__team'>
                    <h5 className='info__team-title'>Команда</h5>
                    <div className='info__team-list'>
                      <div className='info__team-list__item'>
                        <img src={member_1} alt='Иван Иванов' width={60} />
                        <div className='member__descr'>
                          <div className='member__name'>Иван Иванов</div>
                          <div className='member__role'>Writing</div>
                        </div>
                      </div>
                      <div className='info__team-list__item'>
                        <img src={member_2} alt='Степан Юрьев' />
                        <div className='member__descr'>
                          <div className='member__name'>Степан Юрьев</div>
                          <div className='member__role'>Tagging</div>
                        </div>
                      </div>
                      <div className='info__team-list__item'>
                        <img src={member_3} alt='Кристина Абрамова' />
                        <div className='member__descr'>
                          <div className='member__name'>Кристина Абрамова</div>
                          <div className='member__role'>Writing</div>
                        </div>
                      </div>
                      <div className='info__team-list__item'>
                        <img src={member_4} alt='Лиза Попова' />
                        <div className='member__descr'>
                          <div className='member__name'>Лиза Попова</div>
                          <div className='member__role'>Clean Advertising</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </Map>
          </YMaps>
        </div>
      </div>

      {props.isMobile && (
        <div className='map-container_m section__padding'>
          <div className='map-container_m-bg'>
            <h3 className='map-container_m__title'>Карта проектов</h3>
            <Link to='/mobile-map'>
              <Button title='Открыть' isWhite link='/mobile-map' />
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default InteractiveMap
