import React from 'react'
import './creation.css'
import image_m from '../../assets/creation-image-m.png'
import { Button } from '../../components'
import { Link } from 'react-router-dom'

const Creation = () => {
  return (
    <div className='creation section__padding'>
      <div className='creation__container'>
        <div className='creation__content'>
          <p className='creation__subtitle subtitle__mobile'>Граффити Пермь</p>
          <h1 className='creation__title'>Создаем сообщество уличных художников</h1>
          <p className='creation__subtitle subtitle_desk'>Преображаем городское пространство путем развития street art искусства</p>
          <p className='creation__subtitle subtitle__mobile subtitle__mobile_bottom'>Преображаем городское пространство путем развития street art искусства</p>
          <a href='#map' className='creation__link'>
            <Button title='карта проектов' isBlack />
          </a>
          <Link to='mobile-map' className='creation__link_m'>
            <Button title='карта проектов' isBlack />
          </Link>
        </div>
      </div>
      <img src={image_m} alt='' className='image_m' />
    </div>
  )
}

export default Creation
