import React from 'react'
import { ExternalNavbar, InternalNavbar } from '../../components'

import './header.css'

const Header = ({ title }) => {
  return (
    <div className='header'>
      <ExternalNavbar />
      <InternalNavbar title={title} />
    </div>
  )
}

export default Header
