import './App.css'
import React from 'react'
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Header, Creation, CTA, Footer, Partners, Team, Events, News, InteractiveMap } from './containers'

function App() {
  return (
    <div className='App'>
      <Header />
      <Creation />
      <Events />
      <News />
      <InteractiveMap isPad isNone isMobile />
      <CTA />
      <Team />
      <Partners />
      <Footer />
    </div>
  )
}

export default App
