import React from 'react'
import { CustomNavigation, Button } from '../../components'
import member_1 from '../../assets/member_1.png'
import member_2 from '../../assets/member_2.png'
import member_3 from '../../assets/member_3.png'
import member_4 from '../../assets/member_4.png'
import creators from '../../data/creators.json'
import './team.css'
import { Link } from 'react-router-dom'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

const community = [
  {
    id: 'e31eed94-c305-11ed-afa1-0242ac120002',
    name: 'Alex Besto',
    role: 'Geometrica',
    bio: 'Уличный современный художник. Придумывая идею работы, изучает исторические особенности города, где будет находиться работа. Так что всегда получается что-то новое и интересное.',
    avatarUrl: './images/0.png',
  },
  {
    id: 'e31ef05a-c305-11ed-afa1-0242ac120002',
    name: 'Blazt',
    role: 'Individual style',
    bio: 'Предпочитает рисовать надписи или рисунки на разных поверхностях: стенах зданий и переходов, вагонах поездов, а также заборах и прочих вертикальных поверхностях.',
    avatarUrl: './images/1.png',
  },
  {
    id: 'e31ef19a-c305-11ed-afa1-0242ac120002',
    name: 'Gook',
    role: 'Individual style',
    bio: 'Участвовал в выставке «Культурный слой», которая проходила в Центральном выставочном зале, был художником, представил свои работы на холстах.',
    avatarUrl: './images/2.png',
  },
  {
    id: 'e31ef2d0-c305-11ed-afa1-0242ac120002',
    name: 'Grezo',
    role: 'Wild style',
    bio: 'Предпочитает рисовать надписи или рисунки на разных поверхностях: стенах зданий и переходов, вагонах поездов, а также заборахи прочих вертикальных поверхностях.',
    avatarUrl: './images/3.png',
  },
]

const Team = () => {
  return (
    <div className='team section__padding' id='community'>
      <CustomNavigation title='Художники' id_next='nextC' id_prev='prevC' to='/creators' />
      <Swiper modules={[Navigation]} spaceBetween={20} slidesPerView={4} navigation={{ nextEl: '#nextC', prevEl: '#prevC' }} style={{ marginTop: 34 }} className='swiper__team'>
        {creators.map((member, index) => (
          <SwiperSlide key={index}>
            <div className='team__member' key={index}>
              <div className='team__member_avatar' style={{ backgroundImage: `url(${member.avatarUrl})` }}>
                <div className='link_hover'></div>
              </div>
              <h4 className='team__member-title'>{member.name}</h4>
              <p className='team__member-role'>{member.role}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='team__list'>
        {community.map((member, index) => (
          <div className='team__member' key={index}>
            <div className='team__member_avatar' style={{ backgroundImage: `url(${member.avatarUrl})` }}>
              <div className='link_hover'></div>
            </div>
            <h4 className='team__member-title'>{member.name}</h4>
            <p className='team__member-role'>{member.role}</p>
          </div>
        ))}
      </div>

      <div className='btn__container'>
        <Link to='/form'>
          <Button title='присоединиться' isBlack isCenter />
        </Link>
      </div>
    </div>
  )
}

export default Team
