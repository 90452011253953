import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import './internalNavbar.css'

const nav = [
  { title: 'Мероприятия', href: '#events' },
  { title: 'Новости', href: '#news' },
  { title: 'Карта проектов', href: '#map' },
  { title: 'Художники', href: '#community' },
  { title: 'Партнеры', href: '#partners' },
]
const mnav = [
  { title: 'Мероприятия', href: '#events' },
  { title: 'Новости', href: '#news' },
  { title: 'Карта проектов', href: 'mobile-map' },
  { title: 'Художники', href: '#community' },
  { title: 'Партнеры', href: '#partners' },
]

const InternalNavbar = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [active, setActive] = useState(false)

  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  // const getPageTitle = (title) => {
  //   if (title === 'mobile-map') {
  //     setTitle('Карта проектов')
  //   }
  //   if (title === 'events') {
  //     setTitle('Реализованные мероприятия')
  //   }
  //   if (title === 'creators') {
  //     setTitle('Художники')
  //   }
  // }

  const location = useLocation()
  function handleButtonClick(hash) {
    // const yOffset = -116
    // const element = hash ? document.getElementById(hash) : null
    // const y = element ? element.getBoundingClientRect().top + window.pageYOffset + yOffset : null

    // window.scrollTo({ top: y, behavior: 'smooth' })
    return hash ? document.querySelector(hash).scrollIntoView({ block: 'start', behavior: 'smooth' }) : null
  }

  useEffect(() => {
    handleButtonClick(location.hash)
    // getPageTitle(location.pathname)
  }, [location])

  const activeItem = (e) => setActive(+e.target.dataset.item)

  return (
    <div className='internalNavbar'>
      {location.pathname !== '/' ? (
        <svg className='internalNavbar__backArrow' width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' onClick={goBack}>
          <path d='M23.334 12.5L15.666 20.1684L23.334 27.8364' stroke='#111111' strokeWidth='1.66667' />
        </svg>
      ) : (
        <svg xmlns='http://www.w3.org/2000/svg' width='34' height='25' viewBox='0 0 34 25' fill='none' className='main-logo'>
          <path
            d='M15.625 0H0.781252C0.349778 0 0 0.349778 0 0.781251V7.03094C0 7.46241 0.349778 7.81219 0.781252 7.81219H6.59778C6.8372 7.81219 7.03129 8.00628 7.03129 8.2457C7.03129 8.44887 6.89019 8.62479 6.69186 8.66888L0.611701 10.0206C0.254284 10.1001 4.69663e-07 10.413 3.21386e-06 10.7791L2.913e-05 13.5546C6.35716e-05 17.0373 0.000113722 22.1085 9.26538e-05 24.2215C8.83513e-05 24.6529 0.349876 24.9993 0.781364 24.9993H7.03129C7.46277 24.9993 7.81283 24.6534 7.81393 24.2219C7.82164 21.1905 7.85744 12.1694 7.81254 10.5473V10.4684C7.81254 9.00143 9.00179 7.81219 10.4688 7.81218L15.625 7.81218C16.0565 7.81218 16.4063 7.4624 16.4063 7.03093V0.781252C16.4063 0.349778 16.0565 0 15.625 0Z'
            fill='url(#paint0_linear_101_11275)'
          />
          <path
            d='M15.625 0H0.781252C0.349778 0 0 0.349778 0 0.781251V7.03094C0 7.46241 0.349778 7.81219 0.781252 7.81219H6.59778C6.8372 7.81219 7.03129 8.00628 7.03129 8.2457C7.03129 8.44887 6.89019 8.62479 6.69186 8.66888L0.611701 10.0206C0.254284 10.1001 4.69663e-07 10.413 3.21386e-06 10.7791L2.913e-05 13.5546C6.35716e-05 17.0373 0.000113722 22.1085 9.26538e-05 24.2215C8.83513e-05 24.6529 0.349876 24.9993 0.781364 24.9993H7.03129C7.46277 24.9993 7.81283 24.6534 7.81393 24.2219C7.82164 21.1905 7.85744 12.1694 7.81254 10.5473V10.4684C7.81254 9.00143 9.00179 7.81219 10.4688 7.81218L15.625 7.81218C16.0565 7.81218 16.4063 7.4624 16.4063 7.03093V0.781252C16.4063 0.349778 16.0565 0 15.625 0Z'
            fill='url(#paint1_linear_101_11275)'
          />
          <path
            d='M32.8126 0.000639571L17.969 0.000674084C17.5375 0.000675087 17.1878 0.350453 17.1878 0.781926V6.98111C17.1878 8.04865 17.3685 9.10399 17.7256 10.1101C18.8092 13.1636 21.1754 19.8266 21.4454 20.547C21.5681 20.8742 21.2111 21.3343 20.7814 20.9376C19.3808 19.6448 13.1642 10.8207 12.1095 9.37541C11.0548 7.93014 8.5938 8.59418 8.59378 10.5473L8.59377 24.2181C8.59377 24.6496 8.94355 25.0001 9.37502 25.0001H24.2189C24.6503 25.0001 25.0001 24.6552 25.0001 24.2237V8.97454C25.0001 8.76094 25.1733 8.59422 25.3869 8.59422C25.6005 8.59422 25.7736 8.76118 25.7737 8.97478C25.7743 11.637 25.7802 20.7984 25.7812 24.2243C25.7813 24.6558 26.1311 25.0001 26.5626 25.0001H32.8126C33.2441 25.0001 33.5939 24.6529 33.5939 24.2214L33.5939 21.2036C33.5939 15.1563 33.5939 4.33528 33.5939 0.778624C33.5939 0.347152 33.2441 0.000638568 32.8126 0.000639571Z'
            fill='url(#paint2_linear_101_11275)'
          />
          <path
            d='M32.8126 0.000639571L17.969 0.000674084C17.5375 0.000675087 17.1878 0.350453 17.1878 0.781926V6.98111C17.1878 8.04865 17.3685 9.10399 17.7256 10.1101C18.8092 13.1636 21.1754 19.8266 21.4454 20.547C21.5681 20.8742 21.2111 21.3343 20.7814 20.9376C19.3808 19.6448 13.1642 10.8207 12.1095 9.37541C11.0548 7.93014 8.5938 8.59418 8.59378 10.5473L8.59377 24.2181C8.59377 24.6496 8.94355 25.0001 9.37502 25.0001H24.2189C24.6503 25.0001 25.0001 24.6552 25.0001 24.2237V8.97454C25.0001 8.76094 25.1733 8.59422 25.3869 8.59422C25.6005 8.59422 25.7736 8.76118 25.7737 8.97478C25.7743 11.637 25.7802 20.7984 25.7812 24.2243C25.7813 24.6558 26.1311 25.0001 26.5626 25.0001H32.8126C33.2441 25.0001 33.5939 24.6529 33.5939 24.2214L33.5939 21.2036C33.5939 15.1563 33.5939 4.33528 33.5939 0.778624C33.5939 0.347152 33.2441 0.000638568 32.8126 0.000639571Z'
            fill='url(#paint3_linear_101_11275)'
          />
          <defs>
            <linearGradient id='paint0_linear_101_11275' x1='16.7969' y1='0' x2='16.7969' y2='25.0001' gradientUnits='userSpaceOnUse'>
              <stop stop-color='#63FBF2' />
              <stop offset='1' stop-color='#7A58FF' />
            </linearGradient>
            <linearGradient id='paint1_linear_101_11275' x1='16.7969' y1='0' x2='16.7969' y2='25.0001' gradientUnits='userSpaceOnUse'>
              <stop stop-color='#63FBF2' />
              <stop offset='1' stop-color='#7A58FF' />
            </linearGradient>
            <linearGradient id='paint2_linear_101_11275' x1='16.7969' y1='0' x2='16.7969' y2='25.0001' gradientUnits='userSpaceOnUse'>
              <stop stop-color='#63FBF2' />
              <stop offset='1' stop-color='#7A58FF' />
            </linearGradient>
            <linearGradient id='paint3_linear_101_11275' x1='16.7969' y1='0' x2='16.7969' y2='25.0001' gradientUnits='userSpaceOnUse'>
              <stop stop-color='#63FBF2' />
              <stop offset='1' stop-color='#7A58FF' />
            </linearGradient>
          </defs>
        </svg>
      )}

      <p className='internalNavbar-title'>{title}</p>

      <div className='internalNavbar__burger' onClick={() => setIsOpen(!isOpen)}></div>

      {isOpen && (
        <div className='internalNavbar__mobileNav'>
          <div className='internalNavbar__close' onClick={() => setIsOpen(!isOpen)}></div>
          <div className='internalNavbar__mobileNav-links'>
            {mnav.map((item, index) => (
              <Link
                to={'/' + item.href}
                className='internalNavbar__nav-list__item'
                onClick={() => {
                  setIsOpen(!isOpen)
                  handleButtonClick()
                }}
                key={index}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      )}

      <nav className='internalNavbar__nav-list'>
        {nav.map((item, index) => (
          <Link
            to={'/' + item.href}
            className={`internalNavbar__nav-list__item ${index === active ? 'active-item' : ''}`}
            onClick={(e) => {
              activeItem(e)
              handleButtonClick()
            }}
            data-item={index}
            key={index}
          >
            {item.title}
          </Link>
        ))}
      </nav>
    </div>
  )
}

export default InternalNavbar
