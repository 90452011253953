import React from 'react'
import './partners.css'
import { CustomNavigation, Button } from '../../components'
import partner from '../../assets/partner.svg'
// import partner2 from './images/partner2.svg'
// import partner3 from './images/partner3.svg'
// import partner4 from './images/partner4.svg'
// import partner5 from './images/partner5.png'
import partnerZero from '../../assets/partner-zero.svg'

const partners = [
  { title: 'Городская дума Перми', logoURL: partner },
  { title: 'Фонд губернатора', logoURL: './images/partner2.svg' },
  { title: 'Городисский и партнеры', logoURL: './images/partner3.svg' },
  { title: 'Компания ДНК', logoURL: './images/partner4.svg' },
  { title: 'ЦВЗ\nПерми', logoURL: './images/partner5.svg' },
  { title: 'Бизнес\nинкубатор', logoURL: './images/partner6.svg' },
]

const Partners = () => {
  return (
    <div className='partners section__padding' id='partners'>
      {/* <h3 className='partners__title'>Партнёры</h3> */}
      <CustomNavigation title='Партнёры' isDisabled />
      <div className='partners__list'>
        {partners.map((partner, index) => (
          <div className='partner' key={index}>
            {/* <div className='partner_hover'></div> */}
            <img src={partner.logoURL} alt={partner.title} />
            <p className='partner__name'>{partner.title}</p>
          </div>
        ))}
        {/* <img src={partner} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' />
        <img src={partnerZero} alt='' /> */}
      </div>
      <Button title='присоединиться' isBlack isCenter />
    </div>
  )
}

export default Partners
