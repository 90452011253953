import React from 'react'
// import { Navigation } from 'swiper'
import { Link } from 'react-router-dom'
import './customNavigation.css'
import prev from '../../assets/prev.svg'
import next from '../../assets/next.svg'
import { useSwiper } from 'swiper/react'

const CustomNavigation = (props) => {
  const swiper = useSwiper()
  return (
    <div className='carousel-navigation'>
      {props.isMapPage && (
        <Link to='/' className='back-map-link'>
          <img src={prev} alt='' className='backMap' />
        </Link>
      )}
      <h3 className='carousel-navigation__title'>{props.title}</h3>
      {!props.isDisabled && (
        <div className='carousel-navigation__controls'>
          <Link to={props.to}>Смотреть&nbsp;все</Link>
          <button className='prev' onClick={(swiper) => swiper.slidePrev()} id={props.id_prev}>
            <img src={prev} alt='' />
          </button>
          <button className='next' onClick={(swiper) => swiper.slideNext()} id={props.id_next}>
            <img src={next} alt='' />
          </button>
        </div>
      )}
    </div>
  )
}

export default CustomNavigation
