import React, { useEffect } from 'react'
import { Header, Footer } from '../../containers'
import { useLocation } from 'react-router-dom'
import { Gallery } from '../../components'
import images from '../../data/images.json'
import './eventsPage.css'

const schedule = [
  {
    date: '21 ноя, 2022 — 05 дек, 2022',
    title: 'Мастер–классa\nо стилю «бомбинга»',
  },
  { date: '21 ноя, 2022 — 05 дек, 2022', title: 'Мастер–класс по стилю\n«Теггинг. Хитрости мастерства»' },
  { date: '21 ноя, 2022 — 05 дек, 2022', title: 'Мастер–класс\nпо стилю «Скетчинг»' },
  { date: '21 ноя, 2022 — 05 дек, 2022', title: 'Мастер–класс\nпо стилю «Street style»' },
]
const schedule2 = [
  {
    date: '12 июл, 2023',
    title: 'Начало ярмарки',
  },
  { date: '13 июл, 2023', title: 'Дизайнерская одежда\nи украшения' },
  { date: '14 июл, 2023', title: 'Пермская арт-сцена\n(художники)' },
  { date: '15 июл, 2023', title: 'Детское творчество' },
  { date: '16 июл, 2023', title: 'Народно-художественные промыслы' },
]
const EventsPage = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location])
  return (
    <div className='App'>
      <Header />
      <div className='event-container hack-event '>
        <div className='event__status-container hack-event-m'>
          <p className='event__status event-text_secondary'>Проведённое мероприятие</p>
          <img src='./images/check.svg' alt='check mark' />
        </div>
        <div className='event__content'>
          <div className='event__info hack-event-m'>
            <div className='event__info-title__container'>
              <p className='event__info-type event-text_secondary'>Выставка</p>
              <h3 className='event__info-title event-text_primary'>Культурный слой</h3>
              <p className='event__info-date event-text_secondary'>21 ноя, 2022 — 05 дек, 2022</p>
            </div>
            <div className='event__info-about'>
              <h3 className='event__info-title event-text_primary'>О мероприятии</h3>
              <p className='event__info-descr'>Концепция выставки напрямую связана со спецификой уличного искусства и его сложности в восприятии и самопрезентации. Ведь и сегодня граффити или тэг в общественном месте остается актом вандализма, а не произведением искусства, вынесенным на городской холст, вместо стен музея.</p>
              <p className='event__info-descr' style={{ marginTop: '16px' }}>
                Выставка «Культурный слой» — это призыв взглянуть на стрит арт с другой стороны, как на сложный художественный организм со своими целями, направлениями, стилямии научиться даже в маленьком теге видеть прекрасное. Проект реализован при поддержке департамента культурыи молодежной политики администрации города Перми.
              </p>
            </div>
            <div className='event__place'>
              <h4 className='event__place-title'>Место проведения</h4>
              <p className='event__place-address'>Арт–пространство ЦВЗ «Другие круги», ул&nbsp;Комсомольский пр., 10, г. Пермь</p>
            </div>
          </div>
          <div className='event__gallery-container hack-event-gallery event-gal-m'>
            <Gallery images={images} />
          </div>
          <div className='event__schedule-container hack-event-m'>
            {schedule.map((event, i) => (
              <div className='event__schedule-item' key={i}>
                <p className='event__schedule-item__date  event-text_secondary'>{event.date}</p>
                <p className='event__schedule-item__title event-text_primary'>{event.title}</p>
              </div>
            ))}
          </div>
          <div className='event__gallery-container hack-event-gallery event-gal-d'>
            <Gallery images={images} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EventsPage
