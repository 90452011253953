import React, { useState, useEffect } from 'react'
import s from './gallery.module.css'

const Gallery = ({ images }) => {
  const [selectedImg, setSelectedImg] = useState(images[0])
  useEffect(() => {
    setSelectedImg(images[0])
  }, [images])
  const handleClick = (image) => {
    setSelectedImg(image)
  }
  return (
    <>
      {images !== undefined || null || '' ? (
        <div className={s.gallery}>
          <div className={s.galleryMain}>{selectedImg && <img src={selectedImg.img} alt='' />}</div>
          <div className={s.galleryList}>
            {images.map((image) => (
              <div className={`${s.galleryList__item} ${selectedImg && selectedImg.id === image.id ? s.active : ''}`} key={image.id}>
                <img src={image.img} alt='' onClick={() => handleClick(image)} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Gallery
