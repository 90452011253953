import React from 'react'
import { Link } from 'react-router-dom'
import './button.css'

const Button = (props) => {
  let className = 'link-button'
  if (props.isWhite) {
    className += ' link-button_white'
  } else if (props.isBlack) {
    className += ' link-button_black'
  }
  if (props.isCenter) {
    className += ' link-button_center'
  }

  return (
    <button className={className} to={props.link} style={props.style}>
      {props.title}
    </button>
  )
}

export default Button
