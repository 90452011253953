import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './index.css'
import App from './App'
import { TouchMapPage, FormPage, CreatorsPage, EventsPage, EventsPageSub } from './pages'
// import { InteractiveMap } from './containers'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Routes>
      {/* <React.StrictMode> */}
      <Route path='/' element={<App />} />
      <Route path='mobile-map' element={<TouchMapPage />} />
      <Route path='form' element={<FormPage />} />
      <Route path='creators' element={<CreatorsPage />} />
      <Route path='events' element={<EventsPage />} />
      <Route path='eventssub' element={<EventsPageSub />} />
      {/* </React.StrictMode> */}
    </Routes>
  </BrowserRouter>
)
