import React from 'react'
import './cta.css'
import { Button } from '../../components'
import { Link } from 'react-router-dom'

const CallToAction = () => {
  return (
    <div className='cta section__padding'>
      <div className='cta__container' style={{ backgroundImage: 'url(./images/cta.png)' }}>
        <div className='cta__content'>
          <h2 className='cta__title'>
            Нанеси свой рисунок
            <br />
            на&nbsp;здание города
          </h2>
          <p className='cta__subtitle'>Мы подготовили реестр объектов доступных для&nbsp;нанесения в&nbsp;различных районах города</p>
          <a href='#map'>
            <Button title='карта проектов' isWhite link='/form' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
