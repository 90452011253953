import React, { useEffect, useState } from 'react'
import { ExternalNavbar, Button } from '../../components'
import { Footer } from '../../containers'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import './formPage.css'

const FormPage = () => {
  const [showPopup, setShowPopup] = useState(false)

  const handleSubmit = async (values, e, { resetForm }) => {
    e.preventDefault()
    // await new Promise((r) => setTimeout(r, 500))
    let response = await fetch('https://script.google.com/macros/s/AKfycbxVyWJm4cIO3OFvmXm0XzpLd-99O8qnZ4DkqUva7ZSM0rsEK8RVDYlXU_RAToqI0_Bc/exec', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(values),
    })
    let result = await response.json()
    console.log(result.message)
    setShowPopup(true)
    resetForm()
  }
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location])

  return (
    <div className='App' style={{ gap: 0 }}>
      {showPopup && (
        <div className='popup'>
          <div className='popup-container'>
            <div className='popup-content'>
              <h4 className='popup-title'>Ваша заявка успешно отправлена!</h4>
              <p className='popup-subtitle'>С вами скоро свяжутся</p>
              <Link to='/'>
                <Button title='вернуться на главную' isBlack />
              </Link>
            </div>
          </div>
        </div>
      )}
      <ExternalNavbar />
      <div className='close-btn__container'>
        <button onClick={goBack} className='close-btn'>
          Закрыть
        </button>
      </div>
      <div className='form__container'>
        <h2>Заявка на&nbsp;вступление в&nbsp;сообщество и&nbsp;создание проекта граффити</h2>
        <Formik
          initialValues={{
            name: '',
            place: '',
            graffiti: '',
            phone: '',
            email: '',
            comment: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className='form'>
              <div className='form-item'>
                <Field id='name' name='name' className='form-item__input' placeholder='ФИО' />
                <label htmlFor='name' className='form-item__label'>
                  ФИО
                </label>
              </div>
              <div className='form-item'>
                <Field id='place' name='place' className='form-item__input' placeholder='Место нанесения' />
                <label htmlFor='place' className='form-item__label'>
                  Место нанесения
                </label>
              </div>
              <div className='form-item'>
                <Field id='graffiti' name='graffiti' className='form-item__input' placeholder='Ссылка на ваш эскиз' />
                <label htmlFor='graffiti' className='form-item__label'>
                  Ссылка на ваш эскиз
                </label>
              </div>
              <div className='form-item'>
                <Field id='phone' name='phone' className='form-item__input' type='tel' placeholder='Номер телефона' />
                <label htmlFor='phone' className='form-item__label'>
                  Номер телефона
                </label>
              </div>
              <div className='form-item'>
                <Field id='email' name='email' className='form-item__input' type='email' placeholder='Эл. почта' />
                <label htmlFor='email' className='form-item__label'>
                  Эл. почта
                </label>
              </div>
              <div className='form-item'>
                <Field id='comment' name='comment' as='textarea' className='form-item__area' placeholder='Комментарий' />
                <label htmlFor='comment' className='form-item__label'>
                  Комментарий
                </label>
              </div>
              <p className=''>Отправляя заявку, я&nbsp;принимаю Политику конфиденциальности и&nbsp;Условия использования Граффити Пермь</p>
              <button type='submit' disabled={isSubmitting}>
                отправить заявку
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  )
}

export default FormPage
