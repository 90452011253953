import React from 'react'
import './avatar.css'
import { Navigation } from 'swiper'

// const colors = ['#7FCAFA', '#FD9F3B', '#43D0A7', '#FF5577', '#B788F4', '#FFD55B']
// function createAvatar(arr, str) {
//   let randomColor = arr[Math.floor(Math.random() * arr.length)]
//   const getInitials = str.split('').map((item) => item[0])
//   return (
//     <div style={{ backgroundColor: `${randomColor}` }}>
//       <span></span>
//     </div>
//   )
// }

const Avatar = ({ name, role, avatarUrl }) => {
  return (
    <div className='team__member'>
      <div className='team__member_avatar' style={{ backgroundImage: `url(${avatarUrl})` }}>
        {/* <img src={avatarUrl} alt={name} /> */}
      </div>
      <h4 className='team__member-title'>{name}</h4>
      <p className='team__member-role'>{role}</p>
    </div>
  )
}

export default Avatar
