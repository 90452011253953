import React, { useState, useEffect } from 'react'
import { Avatar, Profile, Button, Gallery } from '../../components'
import { Link } from 'react-router-dom'
import './mapInfo.css'

const MapInfo = ({ title, status, address, images, info, team }) => {
  const [open, setOpen] = useState(false)
  const handleCard = () => {
    setOpen(!open)
  }
  return (
    <div className={`info ${open ? 'info_up' : 'info_down'}`}>
      <div className='info__mobile-bar' onClick={handleCard}>
        <div className='info__mobile-bar__control'></div>
      </div>
      <div className='info__name'>
        <p className='info__name-type'>{status}</p>
        {title !== undefined || null || '' ? <h4 className='info__name-title'>{title}</h4> : null}
        <p className='info__name-address'>{address}</p>
      </div>
      {images !== undefined || null || '' ? <Gallery images={images} /> : null}
      {status === 'Реализованный проект' ? (
        <>
          <div className='info__about'>
            <h5 className='info__about-title'>О проекте</h5>
            <p className='info__about-descr'>{info}</p>
          </div>
          <div className='info__team'>
            <h5 className='info__team-title'>Команда</h5>
            {team && (
              <div className='info__team-list'>
                {team.map((member, i) => (
                  <div className='info__team-list__item' key={i}>
                    <img src={member.imgUrl} alt={member.name} width={60} />
                    <div className='member__descr'>
                      <div className='member__name'>{member.name}</div>
                      <div className='member__role'>{member.role}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <Link to='/form'>
          <Button title='оставить заявку' isBlack style={{ marginTop: '40px' }} />
        </Link>
      )}
    </div>
  )
}

export default MapInfo
